// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import ahoy from "ahoy.js"

import "@fortawesome/fontawesome-free/css/all.css";
import "chartkick/chart.js"

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import jQuery from "jquery";
window.$ = window.jQuery = jQuery;
import "bootstrap";

const setFullHeight = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}

// Set the initial height
setFullHeight();

// Recalculate and set the height whenever the window is resized
window.addEventListener('resize', setFullHeight);

document.addEventListener('turbolinks:load', function() {
    // check if .dashboard-section is present on the page
    if ($('.dashboard-section').length) {
        
        function setDashboardMinHeight() {
            // Check for the presence of each element and get their heights, default to 0 if not found
            var headerHeight = ($('.header-section').length) ? $('.header-section').outerHeight() : 0;
            var navHeight = ($('.dash-m-nav').length) ? $('.dash-m-nav').outerHeight() : 0;
            var ctaSection = ($('.cta-section').length) ? $('.cta-section').outerHeight() : 0;
            
            // calculate the desired min-height
            var minHeight = window.innerHeight - headerHeight - navHeight - ctaSection;
            
            // set the min-height for dashboard section
            $('.dashboard-section').css('min-height', minHeight + 'px');
 
        }

        // call the function initially
        setDashboardMinHeight();

        // adjust the height on window resize
        $(window).resize(setDashboardMinHeight);
    }
});






